import React from "react"
import { graphql } from "gatsby"
import { RiSendPlane2Line } from "react-icons/ri"
import ReCAPTCHA from "react-google-recaptcha"
import { navigate } from "gatsby-link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Img from "gatsby-image"
import TextField from "@material-ui/core/TextField" 
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import {
  withStyles
} from '@material-ui/core/styles';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(TextField);

const CssFormControl = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'grey',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'grey',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
})(FormControl);

const CssCard = withStyles(theme =>({
  root: {
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('xs')]: {
      boxShadow: theme.shadows[1],
    },
  },
}))(Card);


const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export const pageQuery = graphql`
  query ContactQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 160)
      frontmatter {
        title
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    contactImage: file(relativePath: { eq: "future_app.png" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.data = props
    this.recaptchaRef = React.createRef()
    this.state = {
      name: "",
      email: "",
      company: "",
      subject: "",
      message: "",
      verified: false,
    }
 
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onCaptchaChange = this.onCaptchaChange.bind(this)
  }

  onCaptchaChange(value) {
    if (value) this.setState({ verified: true })
  }

  handleChange = e => {
    const value = e.target.value
    this.setState({
      ...this.state,
      [e.target.name]: value,
    })
  }

  handleSubmit = async e => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = await this.recaptchaRef.current.getValue()

    console.log(this.state)
    if (recaptchaValue === null || recaptchaValue === "") {
      this.recaptchaRef.current.reset()
      this.setState({
        verified: false,
      })
      alert("Error: Invalid Captcha")
      return
    }

    await fetch("contact/?no-cache=1", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha": RECAPTCHA_KEY,
        "g-recaptcha-response": recaptchaValue,
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    const { data } = this.data
    const { markdownRemark} = data // data.markdownRemark holds your post data
    const { frontmatter, html, excerpt } = markdownRemark

    return (
      <Layout className="contact-page">
        <SEO
          title={frontmatter.title + " - Meek Code"}
          description={excerpt}
        />
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
      </Helmet>
        <div className="wrapper">
          <h1>{frontmatter.title}</h1>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <div className="contact-row">
            <CssCard className="contact-card">
              <CardContent>
                <form
                  className="contact-form"
                  action="/thanks"
                  name="contact-us"
                  method="POST"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  // data-netlify-recaptcha="true"
                  onSubmit={e => this.handleSubmit(e)}
                >
                  <input hidden name="bot-field" />
                  <input type="hidden" name="form-name" value="contact-us" />

                  <CssTextField
                    label="Name"
                    variant="outlined"
                    size="small"
                    name="name"
                    value={this.state.name}
                    onChange={this.handleChange}
                    required
                  />

                  <div className="contact-spacing" />

                  <CssTextField
                    label="Email"
                    variant="outlined"
                    size="small"
                    type="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    required
                  />
                  <div className="contact-spacing" />

                  <CssTextField
                    label="Company (Optional)"
                    variant="outlined"
                    size="small"
                    name="company"
                    value={this.state.company}
                    onChange={this.handleChange}
                  />
                  <div className="contact-spacing" />

                  <CssFormControl  variant="outlined" size="small" required>
                    <InputLabel id="lbl_category">Subject</InputLabel>
                    <Select
                      native
                      label="Subject *"
                      labelId="lbl_category"
                      inputProps={{
                        id: "lbl_category",
                      }}
                      name="subject"
                      value={this.state.subject}
                      onChange={this.handleChange}
                    >
                      <option value="" aria-label="None" hidden="hidden" disabled></option>
                      <option value="question">General Question</option>
                      <option value="consultation">Consultation</option>
                      <option value="project_offer">Project/Job Offer</option>
                      <option value="partnership">Partnership</option>
                      <option value="others">Others</option>
                    </Select>
                  </CssFormControl>
                  <div className="contact-spacing" />

                  <CssTextField
                    label="Message"
                    variant="outlined"
                    multiline
                    rows={4}
                    name="message"
                    value={this.state.message}
                    onChange={this.handleChange}
                    required
                  />
                  <div className="contact-spacing" />

                  {/* <div ref={this.recaptchaRef} className="g-recaptcha" data-sitekey={RECAPTCHA_KEY}></div> */}
                  <div className="text-align-right">
                    <ReCAPTCHA
                      className={
                        !this.state.verified ? "captcha" : "hide-captcha"
                      }
                      name="g-recaptcha-response"
                      ref={this.recaptchaRef}
                      sitekey={RECAPTCHA_KEY}
                      onChange={this.onCaptchaChange}
                    />
                  </div>

                  {this.state.verified ? (
                    <p className="text-align-right">
                      <button className="button-sendmsg" type="submit">
                        Send Message{" "}
                        <span className="icon -right">
                          <RiSendPlane2Line />
                        </span>
                      </button>
                    </p>
                  ) : (
                    ""
                  )}
                </form>
              </CardContent>
            </CssCard>
            <div className="contact-image">
              <Img
                fluid={data.contactImage.childImageSharp.fluid}
                alt="lets build"
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact
